import { data } from "jquery";
import React from "react";
//import { Col, Button, Card } from "react-bootstrap";
import { Link } from "react-router-dom";

const Comfasilitas2 = (props) => {
  let nama = "detailruangan?kode=" + props.data.kode;
  return (
    <>
      <li>{props.data.nama}</li>
    </>
  );
};

export default Comfasilitas2;
