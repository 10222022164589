import React, { Component } from "react";
import Main from "../../layout/Main";
import ComServices from "./ComServices";
import $ from "jquery";
import {Helmet} from "react-helmet";
class PageServices extends Component {
  constructor(props) {
    super(props);
    this.state = {
      errors:false,
      list_service: [],
      data_service: {},
    };
  }
  list_detail =data=> {
    fetch("lmn7/dataservice", {
      method: "POST",
      mode: "cors",
      body: JSON.stringify({
        url: "11",
        jns_id: "pengajuan_aktehilang",
        sq:$(".dpd1").val(),
        nlantai:$(".nlantai").val()
      }),
      headers: new Headers({
        "content-type": "application/json",
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.kode === "00") {
         this.setState({
            list_service: data.data2,
            data_service: JSON.stringify(data.data2),
          });
        }
      })
      .catch((error) => {
        this.setState({
          errors: true,
        });
      });
  };
  componentDidMount(){
    this.list_detail();
  }
  render() {
    return (
      <>
      <Helmet>
        <title>LAYANAN REKTORAT</title>
        <meta name="Keywords" content="LAYANAN REKTORAT UNSOED"></meta>
       <meta name="Description" content="LAYANAN REKTORAT UNSOED"></meta>
      </Helmet>
      <Main title="Services">
        <section className="page-cover style style5 p-20">
          <div className="container">
            <div className="row">
              <div className="col-md-12 text-center">
                <h1 className="page-title">LAYANAN REKTORAT</h1>
              </div>
            </div>
          </div>
        </section>
        <section id="best-features" className="innerpage-wrapper pt-5 pb-5">
          <div className="innerpage-section-padding">
            <div className="container">
              <div className="row">
                <div className="col-md-12">
                  <div className="page-heading pb-2">
                    {/* <h2>Our Service</h2> */}
                    <p className="text-dark">
                    DAFTAR LAYANAN REKTORAT
                    </p>
                    <hr className="heading-line" />
                  </div>
                </div>
                {this.state.errors === false ? (
                      this.state.list_service.map((data_service, i) => {
                        return (
                          <ComServices  key={data_service.id} nom={i + 1}  data={data_service} />
                        );
                      })
                    ) : (
                      <></>
                    )}
              </div>
            </div>
          </div>
        </section>
      </Main>
      </>
    )
  }
}
export default PageServices;