import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import Main from "../../layout/Main";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import ImageGallery from 'react-image-gallery';
import "react-image-gallery/styles/css/image-gallery.css";
import Parser from 'html-react-parser';

{/*
const images = [
  {
    original: 'https://raw.githubusercontent.com/xiaolin/react-image-gallery/master/static/10.jpg',
    thumbnail: 'https://raw.githubusercontent.com/xiaolin/react-image-gallery/master/static/10.jpg',
  },
  {
    original: 'https://raw.githubusercontent.com/xiaolin/react-image-gallery/master/static/10.jpg',
    thumbnail: 'https://raw.githubusercontent.com/xiaolin/react-image-gallery/master/static/10.jpg'
  },
  {
    original: 'https://raw.githubusercontent.com/xiaolin/react-image-gallery/master/static/10.jpg',
    thumbnail: 'https://raw.githubusercontent.com/xiaolin/react-image-gallery/master/static/10.jpg'
  },
  {
    original: 'https://raw.githubusercontent.com/xiaolin/react-image-gallery/master/static/10.jpg',
    thumbnail: 'https://raw.githubusercontent.com/xiaolin/react-image-gallery/master/static/10.jpg'
  },
  {
    original: 'https://raw.githubusercontent.com/xiaolin/react-image-gallery/master/static/10.jpg',
    thumbnail: 'https://raw.githubusercontent.com/xiaolin/react-image-gallery/master/static/10.jpg'
  },
  {
    original: 'https://raw.githubusercontent.com/xiaolin/react-image-gallery/master/static/10.jpg',
    thumbnail: 'https://raw.githubusercontent.com/xiaolin/react-image-gallery/master/static/10.jpg'
  },
  {
    original: 'https://raw.githubusercontent.com/xiaolin/react-image-gallery/master/static/10.jpg',
    thumbnail: 'https://raw.githubusercontent.com/xiaolin/react-image-gallery/master/static/10.jpg'
  }
]

*/}
class Detailberita extends Component {
  constructor(props) {
    super(props);
    const foo = window.location.href;
    const dadiurl1 = window.location.href.split("/");
    const dadiurl = window.location.pathname.split("?");
    var params = new window.URLSearchParams(window.location.search);
    let foo2 = params.get('kode');
    this.state = {
      errors: false,
      dadiurl: dadiurl,
      dadiurl1: dadiurl1,
      foo2: foo2,
      hh: {},
      list_fasilitas: [],
      list_gambar: [],
      data_fasilitas: {},
      list_fasilitas2: [],
      data_fasilitas2: {},
      fields: {
        id: "",
        nama_ruang: ""
      },
    };
  }

  list_detail = () => {
    fetch("/lmn7/detaildataberita", {
      method: "POST",
      mode: "cors",
      body: JSON.stringify({
        url: "11",
        jns_id: "detailberita",
        id: this.state.dadiurl1[3],
        jns_id: this.state.dadiurl[0],
        foo2: this.state.foo2
      }),
      headers: new Headers({
        "content-type": "application/json",
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.kode === "00") {
          this.setState({
            hh: data.data[0],
            fields: data.data[0],
            list_gambar: data.daftargambar
          });
        }
      })
      .catch((error) => {
        this.setState({
          errors: true,
        });
      });

  };
  componentDidMount() {
    this.list_detail();
  }

  render() {
    const img = this.state.list_gambar;
    return (
      <>
        <Helmet>
          <title>{this.state.fields["judul"]}</title>
          <meta name="Keywords" content={this.state.fields["judul"]}></meta>
          <meta name="Description" content={this.state.fields["judul"]}></meta>
        </Helmet>
        <Main title="kegiatan Rektorat">
          <section className="page-cover style style5 p-20">
            <div className="container">
              <div className="row">
                <div className="col-md-12 text-center">
                  <h1 className="page-title">Kegiatan Rektorat</h1>
                </div>
              </div>
            </div>
          </section>
          <section className="innerpage-wrapper pt-5 pb-5">
            <div id="hotel-details" className="innerpage-section-padding">
              <div className="container">
                <div className="row">
                  <div className="col-12 col-md-12 col-lg-12 col-xl-12 content-side">
                    <ImageGallery items={img} thumbnailPosition="right" showBullets="true" />
                  </div>
                </div>
                <div className="row pt-4">
                  <div className="col-12 col-md-12 col-lg-9 col-xl-9 content-side">
                    <div className="available-blocks">
                      <h2>Tanggal : {this.state.fields["tanggal"]}</h2>
                    </div>
                  </div>
                  <div className="col-12 col-md-12 col-lg-3 side-bar left-side-bar">
                    <h3 className="text-bold">{(this.state.fields["desc_singkat"])}</h3>
                  </div>
                  <div className="col-12 col-md-12 col-lg-12">
                    <hr />
                    <div className="mt-3" >
                      <div className="tab-content">
                        <div id="hotel-overview" className="tab-pane in active">
                          <div className="row">
                            <div className="col-md-12 col-lg-12 tab-text a-justify">
                              <h3>{this.state.fields["judul"]} </h3>
                            <p className="text-justify"  >{(this.state.fields["desc"])}</p>  
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </Main>
      </>
    );
  }
}
export default Detailberita;

