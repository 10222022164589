import React, { Component } from "react";
// import { Redirect, Link } from "react-router-dom";
// import { Helmet, HelmetProvider } from "react-helmet-async";
import Main from "../../layout/Main";
// import $ from "jquery";
//import { Container, Row, Col, Card, Form, Button } from "react-bootstrap";
//import axios from "axios";

class PageLaboraturium extends Component {
  constructor(props) {
    super(props);
    this.state = {
      resLogin: localStorage.getItem("login_public") ? JSON.parse(localStorage.getItem("login_public")).kode : null,
      datLogin: localStorage.getItem("login_public") ? JSON.parse(localStorage.getItem("login_public")).data[0] : null,
      sessionToken: localStorage.getItem("login_public"),
      loadingAct: false,
      errors: false,
      hh: {
        keterangan: ""
      }
    };
  }
  list_detail = data => {
    fetch("lmn7/laboratorium", {
      method: "POST",
      mode: "cors",
      body: JSON.stringify({
        url: "11",
        jns_id: "laboratorium",
      }),
      headers: new Headers({
        "content-type": "application/json",
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.kode === "00") {
          this.setState({
            hh: data.data2[0]
          });
        }
      })
      .catch((error) => {
        this.setState({
          errors: true,
        });
      });
  };
  componentDidMount() {
    this.list_detail();
  }
  render() {
    return (
      <Main>
        <div>
          <section className="page-cover style style5 p-20">
            <div className="container">
              <div className="row">
                <div className="col-md-12 text-center">
                  <h1 className="page-title">LABORATURIUM UNIVERSITAS JEDRAL SOEDIRMAN</h1>
                </div>
              </div>
            </div>
          </section>
          <section className="innerpage-wrapper pb-5 pt-5">
            <div className="container">
              <div className="row">
                <div className="col-md-7">
                  <h3>LABORATURIUM MEDIS</h3>
                  <br />
                  {this.state.hh.keterangan}
                </div>
                <div className="col-md-5">
                  <img src={this.state.hh.nfile} className="img-fluid" alt="description of image" />
                  <img src={this.state.hh.nfile2} className="img-fluid" alt="description of image" />
                </div>
              </div>
            </div>
          </section>
        </div>
      </Main>
    );
  }
}

export default PageLaboraturium;
