import React, { Component } from "react";
import { Redirect, Link } from "react-router-dom";

import Main from "../../layout/Main";

//import { Container, Row, Col, Card, Form, Button } from "react-bootstrap";
//import axios from "axios";

class Pagelogout extends Component {
  constructor(props) {
    super(props);
    this.state = {
      resLogin: localStorage.getItem("login_public")? JSON.parse(localStorage.getItem("login_public")).kode: null,
      usersmp: "ttdatakeluarga",
      pwd: "",
      nik: "",
      toDirect: false,
      loadingAct: false,
      errors: false,
      notif:null,
    };
  }

  loadingAction = () =>
    this.state.loadingAct === true ? (
      <i className="fa fa-spinner fa-spin"></i>
    ) : (
      <div></div>
    );

  notif = () => {
    if(this.state.notif === true){
      return(<div className="alert alert-success alert-dismissible fade show" role="alert">
        <strong>Login Berhasil !</strong>
        <button
          type="button"
          className="close"
          data-dismiss="alert"
          aria-label="Close"
        >
          <span aria-hidden="true">×</span>
        </button>
      </div>)
    }else if(this.state.notif === false){
      return(<div className="alert alert-danger alert-dismissible fade show" role="alert">
        <strong>Login Gagal !</strong> Harap periksa kembali NIK dan Password
        <button
          type="button"
          className="close"
          data-dismiss="alert"
          aria-label="Close"
        >
          <span aria-hidden="true">×</span>
        </button>
      </div>)
    }else{
      <></>
    }
    
  };

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  handleSubmit = (e) => {
    fetch("lmn7/loginpublic", {
      method: "POST",
      mode: "cors",
      body: JSON.stringify({
        usersmp: this.state.usersmp,
        pwd: this.state.pwd,
        nik: this.state.nik,
		    title:"tes"
      }),
      headers: new Headers({
        "content-type": "application/json",
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.kode === "00") {
          localStorage.setItem("login_public", JSON.stringify(data));
          localStorage.setItem("login_Date", new Date().getTime());
          this.setState({
            sessionToken: JSON.stringify(data),
            resLogin: JSON.parse(localStorage.getItem("login_public")).kode,
            loadingAct: true,
            notif:true,
          });
        } else {
          this.setState({
            resLogin: data.kode,
            loadingAct: true,
            notif:false,
          });
        }
        //loading
        setTimeout(() => {
          this.loadingAction();
          this.setState({
            loadingAct: false,
          });
          this.notif();
          if (this.state.resLogin === "00") {
            this.setState({
              toDirect: true,
            });
          }
          //this.handleDirect();
        }, 1000);
      })
      .catch((error) => {
        this.setState({
          errors: true,
        });
      });
    e.preventDefault();
  };

  componentDidMount(){
    localStorage.clear();
  }


  render() {
    if (this.state.toDirect === true) {
    //  return <Redirect to="/" />;
    }
    return (
      <Main>
        <div>
         tes
        </div>
      </Main>
    );
  }
}

export default Pagelogout;
