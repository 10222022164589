import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import Compakai from "./Compakai";
import Main from "../../layout/Main";

class PagePemakaian extends Component {
  constructor(props) {

    super(props);
    this.state = {
      errors: false,
      list_pakai: [],
      data_pakai: {},
    };

  }

  list_p = () => {
    fetch("lmn7/datadipakai", {
      method: "POST",
      mode: "cors",
      body: JSON.stringify({
        jns_id: "id",
        foo: this.state.foo
      }),
      headers: new Headers({
        "content-type": "application/json",
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.kode === "00") {
          this.setState({
            list_pakai: data.data,
            data_pakai: JSON.stringify(data.data),
          });
        }
      })
      .catch((error) => {
        this.setState({
          errors: true,
        });
      });
  };

  componentDidMount() {
    this.list_p();
  }
  render() {
    return (
      <>
        <Helmet>
          <title>Pemakaian ruangan</title>
          <meta name="Keywords" content="Rektora Unsoed"></meta>
          <meta name="Description" content="Rektora Unsoed"></meta>
        </Helmet>
        <Main title="Pemakaian ruangan">

          <section className="page-cover style style5 p-20">
            <div className="container">
              <div className="row">
                <div className="col-md-12 text-center">
                  <h1 className="page-title">Ruangan yang Sudah di Pesan</h1>
                </div>
              </div>
            </div>
          </section>
          <section className="innerpage-wrapper">
            <div id="thank-you" className="innerpage-section-padding">
              <div className="container">
                <div className="row">
                  <div className="col-12 col-md-12 col-lg-12 col-xl-12 content-side">

                    <div className="">

                      <div className="table-responsive">
                        <table className="table" border="1">
                          <tbody>
                            <tr>

                              <th rowspan="2" align="center" width="5%">Tanggal</th>
                              <th rowspan="2" align="center" width="15%">Nama Ruangan</th>
                              <th colspan="14" width="5%">Jam</th>
                            </tr>
                            <tr>
                              <th>08-09</th>
                              <th>09-10</th>
                              <th>10-11</th>
                              <th>11-12</th>
                              <th>12-13</th>
                              <th>13-14</th>
                              <th>14-15</th>
                              <th>15-16</th>
                              <th>16-17</th>
                              <th>17-18</th>
                              <th>18-19</th>
                              <th>19-20</th>
                              <th>20-21</th>
                              <th>21-22</th>

                            </tr>
                            {this.state.errors === false ? (
                              this.state.list_pakai.map(
                                (data_pakai, i) => {
                                  return (
                                    <Compakai
                                      key={data_pakai.id}
                                      nom={i + 1}
                                      data={data_pakai}
                                    />
                                  );
                                }
                              )
                            ) : (
                              <>-</>
                            )}
                          </tbody>
                        </table>
                      </div>

                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </Main>
      </>
    );
  }
}

export default PagePemakaian;
