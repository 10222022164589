import React, { Component } from "react";
import Main from "../../layout/Main";
import { Helmet } from "react-helmet";

//import $ from "jquery";
//import { Redirect, Link } from "react-router-dom";
//import { HelmetProvider } from "react-helmet-async";
//import { Container, Row, Col, Card, Form, Button } from "react-bootstrap";
//import axios from "axios";

class PagePengaduan extends Component {
  constructor(props) {
    super(props);
    const foo = window.location.href;
    var msgotp2 = "";
    this.state = {
      resLogin: localStorage.getItem("login_public") ? JSON.parse(localStorage.getItem("login_public")).kode : null,
      datLogin: localStorage.getItem("login_public") ? JSON.parse(localStorage.getItem("login_public")).data[0] : null,
      sessionToken: localStorage.getItem("login_public"),
      kodeotp2: "",
      show: false,
      loadpengajuan: false,
      loadingAct: false,
      loadingbtn: false,
      notif: null,
      msg: null,
      msgotp: null,
      errors: false,
      btnsimpan: false,
      nkode: "",
      foo: foo,
      fields: {
        nama: "",
        alamat: "",
        job: "",
        nohp: "",
        isi: "",
      }
    };
  }

  handleValidation() {
    let fields = this.state.fields;
    let errors = {};
    let formIsValid = true;
    //for huruf match(/^[a-zA-Z]+$/)
    //nama
    if (!fields["nama"]) {
      formIsValid = false;
      errors["nama"] = "nama tidak boleh kosong";
    }
    //alamat
    if (!fields["alamat"]) {
      formIsValid = false;
      errors["alamat"] = "Cannot be empty";
    }
    //nohp
    if (!fields["nohp"]) {
      formIsValid = false;
      errors["nohp"] = "Cannot be empty";
    }
    //nohp
    if (typeof fields["nohp"] !== "undefined") {
      if (!fields["nohp"].match(/^-?[0-9]\d*\.?\d*$/)) {
        formIsValid = false;
        errors["nohp"] = "Only Number";
      }
    }
    //pekerjaan
    if (!fields["pekerjaan"]) {
      formIsValid = false;
      errors["pekerjaan"] = "Cannot be empty";
    }
    //isi
    if (!fields["isi"]) {
      formIsValid = false;
      errors["isi"] = "Cannot be empty";
    }

    this.setState({ errors: errors });
    return formIsValid;
  }

  hanldeSubmit = (e) => {
    this.setState({
      btnsimpan: true,
      notif: false,
      msg: ""
    });

    fetch("lmn7/simpanpengaduan", {
      method: "POST",
      mode: "cors",
      body: new FormData(e.target),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.kode === "00") {
          this.setState({
            notif: true,
            kodepindahhalaman: '/successcechkout?kode=' + data.nkode,
            msg: data.pesan
          });
          //loading
          setTimeout(() => {
            this.setState({
              pindahhalaman: true,
              notif: true,
              btnsimpan: false,
              msg: data.pesan,
              loadingAct: true
            });
            //reset form
            //$('form:input').val('');
          }, 4000);
        } else {
          this.setState({
            loadingAct: true,
            notif: true,
            msg: data.pesan,
            btnsimpan: false
          });
          //loading
          setTimeout(() => {
            this.loadingAction();
            this.setState({
              msg: null,
            });
          }, 1000);
        }
      })
      .catch((error) => {
        this.setState({
          errors: true,
          btnsimpan: false
        });
      });
    e.preventDefault();
  };
  handleChange = (field, e) => {
    let fields = this.state.fields;
    fields[field] = e.target.value;
    this.setState({ fields });
    console.log(this.state.fields);
  };
  render() {
    const { loadingAct, nkode, resLogin } = this.state;
    let style = "";
    let htmlbtn = "Kirim OTP";
    let dsbled = "";
    let nn = "/successcechkout?kode=";
    if (this.state.loadingbtn === true) {
      htmlbtn = "...proses";
      dsbled = "disabled";
    }
    if (this.state.loadingAct === true) {
      // return <Redirect to='pengaduan' />;
    }
    if (resLogin !== "00") {
      //return <Redirect to="/login" />;
    }
    return (
      <>
        <Helmet>
          <title>Pengaduan</title>
        </Helmet>
        <Main >
          <div>
            <section className="page-cover style style5 p-20">
              <div className="container">
                <div className="row">
                  <div className="col-md-12 text-center">
                    <h1 className="page-title">Form Pengaduan</h1>
                  </div>
                </div>
              </div>
            </section>
            <section className="innerpage-wrapper pb-4 pt-4">
              <div className="container">
                <div className="row">
                  <div className="col-md-6">
                    <h3>Form pengaduan</h3>
                    <p className="text-justify">
                      Silahkan mengisi from pengaduan dibawah ini
                    </p>
                    <div id="frmpengaduan" className="innerpage-section-padding"></div>
                    <form onSubmit={this.hanldeSubmit}>
                      <input
                        type="hidden"
                        name="kodedd"
                        value={
                          localStorage.getItem("login_public")
                            ? JSON.parse(localStorage.getItem("login_public"))
                              .data[0].kdd
                            : null
                        }
                      />
                      <div className="lg-booking-form-heading">
                        <h3>
                          <b>{this.state.msg}</b>
                        </h3>
                      </div>
                      <div className="form-group">
                        <label>Jenis Pengaduan</label>
                        <select className="form-control" onChange={this.handleChange.bind(this, "jenispengaduan")}
                          value={this.state.fields["jenispengaduan"]}>
                          <option value="1">Kebersihan</option>
                          <option value="2">Kerusahakan Gedung</option>
                          <option value="3">Listrik</option>
                          <option value="4">AC</option>
                          <option value="5">IT</option>
                          <option value="6">Lainnya</option>                        
                        </select>
                        <small style={{ color: "red" }}>
                          {this.state.errors["nama"]}
                        </small>
                      </div>
                      <div className="form-group">
                        <label>Nama</label>
                        <input
                          type="text"
                          name="nama"
                          onChange={this.handleChange.bind(this, "nama")}
                          value={this.state.fields["nama"]}
                          className="form-control"
                          required
                        />
                        <small style={{ color: "red" }}>
                          {this.state.errors["nama"]}
                        </small>
                      </div>
                      <div className="form-group">
                        <label>Alamat</label>
                        <input
                          type="text"
                          name="alamat"
                          onChange={this.handleChange.bind(this, "alamat")}
                          value={this.state.fields["alamat"]}
                          className="form-control"
                          required
                        />
                        <small style={{ color: "red" }}>
                          {this.state.errors["alamat"]}
                        </small>
                      </div>

                      <div className="form-group">
                        <label>No HP</label>
                        <input
                          type="text"
                          name="nohp"
                          onChange={this.handleChange.bind(this, "nohp")}
                          value={this.state.fields["nohp"]}
                          className="form-control"
                          required
                        />
                        <small style={{ color: "red" }}>
                          {this.state.errors["nohp"]}
                        </small>
                      </div>

                      <div className="form-group">
                        <label>Pekerjaan</label>
                        <input
                          type="text"
                          name="job"
                          onChange={this.handleChange.bind(this, "job")}
                          value={this.state.fields["job"]}
                          className="form-control"
                          required
                        />
                        <small style={{ color: "red" }}>
                          {this.state.errors["job"]}
                        </small>
                      </div>

                      <div className="form-group">
                        <label>Isi Pengaduan (Kritik dan saran)</label>
                        <textarea
                          type="text"
                          name="isi"
                          onChange={this.handleChange.bind(this, "isi")}
                          value={this.state.fields["isi"]}
                          className="form-control"
                          required
                        />
                        <small style={{ color: "red" }}>
                          {this.state.errors["isi"]}
                        </small>
                      </div>
                     <div className="row">
                        <div className="col-4 col-md-4"></div>
                        <div className="col-8 col-md-8">{this.state.msgotp}</div>
                      </div>
                      <div className="row">
                        <div className="col-md-12">
                          <div className="form-group">
                            <button type="submit" className="btn btn-secondary"> Kirim </button>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                  <div className="col-md-6">
                    <img className="img-fluid" src={"assets/images/pengaduan.png"} alt="pengadauan"></img>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </Main>
      </>
    );
  }
}

export default PagePengaduan;
