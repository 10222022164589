import React from "react";
//import { Col, Button, Card } from "react-bootstrap";
import { Link } from "react-router-dom";

function ComGridBerita(props) {
    //let nama="detailruangan?kode="+props.data.kode;
    return (
        <>
            <div className="col-md-12 col-lg-12 col-xl-4">
                <div className="grid-block main-block t-grid-block">
                    <div className="main-img t-grid-img">
                        <Link to={'detailberita?kode=' + props.data.url}>
                            <img src={props.data.gambar} className="img-fluid" alt={props.data.judul} />
                        </Link>
                        <div className="main-mask">
                            <ul className="list-unstyled list-inline offer-price-1">
                                <li className="price"><span className="divider">|</span><span className="pkg"> {props.data.desc_singkat}</span></li>
                            </ul>
                        </div>
                    </div>
                    <div className="block-info t-grid-info">
                        <h3 className="block-title"><Link to={'detailberita?kode=' + props.data.url}>{props.data.desc_singkat}</Link></h3>
                    </div>
                </div>
            </div>
        </>
    );
}

export default ComGridBerita;
