import React, { Component } from "react";
import { Redirect, Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import Main from "../../layout/Main";

class PageLogin extends Component {
  constructor(props) {
    super(props);
    this.state = {
      resLogin: localStorage.getItem("login_public") ? JSON.parse(localStorage.getItem("login_public")).kode : null,
      usersmp: "ttdatakeluarga",
      // usersmp: "loginusers",
      pwd: "",
      nohp: "",
      toDirect: false,
      loadingAct: false,
      errors: false,
      notif: null,
    };
  }

  loadingAction = () =>
    this.state.loadingAct === true ? (
      <i className="fa fa-spinner fa-spin"></i>
    ) : (
      <div></div>
    );

  notif = () => {
    if (this.state.notif === true) {
      return (<div className="alert alert-success alert-dismissible fade show" role="alert">
        <strong>Login Berhasil !</strong> Harap tunggu sedang menuju Dasboard
        <button type="button" className="close" data-dismiss="alert" aria-label="Close"> <span aria-hidden="true">×</span> </button>
      </div>)
    } else if (this.state.notif === false) {
      return (<div className="alert alert-danger alert-dismissible fade show" role="alert">
        <strong>Login Gagal !</strong> Harap periksa kembali username dan Password
        <button type="button" className="close" data-dismiss="alert" aria-label="Close" > <span aria-hidden="true">×</span> </button>
      </div>)
    } else {
      <></>
    }

  };

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  handleSubmit = (e) => {
    fetch("lmn7/loginpublic", {
      method: "POST",
      mode: "cors",
      body: JSON.stringify({
        usersmp: this.state.usersmp,
        pwd: this.state.pwd,
        nohp: this.state.nohp,
        title: "login"
      }),
      headers: new Headers({
        "content-type": "application/json",
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.kode === "00") {
          localStorage.setItem("login_public", JSON.stringify(data));
          localStorage.setItem("login_Date", new Date().getTime());
          this.setState({
            sessionToken: JSON.stringify(data),
            resLogin: JSON.parse(localStorage.getItem("login_public")).kode,
            loadingAct: true,
            notif: true,
          });
        } else {
          this.setState({
            resLogin: data.kode,
            loadingAct: true,
            notif: false,
          });
        }
        //loading
        setTimeout(() => {
          this.loadingAction();
          this.setState({
            loadingAct: false,
          });
          //this.notif();
          if (this.state.resLogin === "00") {
            this.setState({
              toDirect: true,
            });
          }
          //this.handleDirect();
        }, 1000);
      })
      .catch((error) => {
        this.setState({
          errors: true,
        });
      });
    e.preventDefault();
  };
  componentDidMount() {
    if (this.state.resLogin === "00") {
      setTimeout(() => {
        this.setState({
          toDirect: true,
        })
      }, 1000)
    }
  }
  render() {
    if (this.state.toDirect === true) {
      return <Redirect to="/ruangan" />;
    }
    return (
      <>
        <Helmet>
          <title>Login</title>
          <meta name="Keywords" content=""></meta>
          <meta name="Description" content=""></meta>
        </Helmet>
        <Main>
          <div>
            <section className="innerpage-wrapper" id="full-page-form">
              <div id="login" className="innerpage-section-padding">
                <div className="container">
                  <div className="row">
                    <div className="col-md-12">
                      <div className="flex-content">
                        <div className="custom-form custom-form-fields">
                          <h3>Login</h3>
                          <p> </p>
                          {this.notif()}
                          <form onSubmit={this.handleSubmit}>
                            <div className="form-group">
                              <input type="text" className="form-control" placeholder="No Hp" required name="nohp" onChange={this.handleChange} /> <span><i className="fa fa-user" /></span>
                            </div>
                            <div className="form-group">
                              <input type="password" className="form-control" placeholder="Password" required name="pwd" onChange={this.handleChange} /> <span> <i className="fa fa-lock" /> </span>
                            </div>
                            <div className="checkbox">
                              <label> <input type="checkbox" /> Remember me </label>
                            </div>
                            <button type="submit" className="btn btn-secondary btn-block mg-r-5 "> {this.loadingAction()} Login </button>
                          </form>
                          <div className="other-links">
                            <p className="link-line"> New Here ? <Link to="/signup"> Signup </Link> </p>
                            <a className="simple-link" href="#"> Forgot Password ? </a>
                          </div>
                        </div>
                        <div className="flex-content-img custom-form-img"> <img src="assets/images/background/log.jpg" className="img-fluid" alt="login-img" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </Main>
      </>
    );
  }
}

export default PageLogin;
