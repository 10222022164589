import React, { Component } from "react";
import { Link, Redirect } from "react-router-dom";
import Main from "../../layout/Main";
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import { Helmet } from "react-helmet";
import ComGridhari from "./ComGridhari";
import ComGridjam from "./ComGridjam";
import $ from 'jquery';
import e from "cors";
import { useState } from 'react';

class Bookingdua extends Component {
  constructor(props) {
    super(props);
    const foo = window.location.href;
    const dadiurl1 = window.location.href.split("/");
    const dadiurl = window.location.pathname.split("?");
    var params = new window.URLSearchParams(window.location.search);
    //var segments = foo.split( '/' );
    var url = foo.split("?")
    var url = url[0];
    // Get the last path:
    var url = url.split("/");
    var segment = url[url.length - 1];
    //var segment = segments[3];
    let foo2 = params.get('kode');
    var tahap = params.get('tahap');
    this.state = {
      resLogin: localStorage.getItem("login_public") ? JSON.parse(localStorage.getItem("login_public")).kode : null,
      usersmp: "ttdatakeluarga",
      dadiurl: dadiurl,
      dadiurl1: "ncek",
      foo2: foo2,
      segment: segment,
      tahap: tahap,
      pindahhalaman: 0,
      kodepindahhalaman: "",
      pwd: "",
      fields: {
        nama: localStorage.getItem("login_public") ? JSON.parse(localStorage.getItem("login_public")).nama : null,
        kd_ruang: "",
        lembaga: "",
        alamat: "",
        nohp: localStorage.getItem("login_public") ? JSON.parse(localStorage.getItem("login_public")).nohp : null,
        nama_ruang: "",
        kd_ruang: "",
      },
      waktu: [],
      tgl_masuk: new Date(),
      tgl_selesai: new Date(),
      categori_waktu: [
        { value: "1", text: "08.00-09.00" },
        { value: "2", text: "09.00-10.00" },
        { value: "3", text: "10.00-11.00" },
        { value: "4", text: "11.00-12.00" }
      ],
      categori_waktusiang: [
        { value: "6", text: "13.00-14.00" },
        { value: "7", text: "14.00-15.00" },
        { value: "8", text: "15.00-16.00" },
        { value: "9", text: "16.00-17.00" }
      ],
      categori_waktumalam: [
        { value: "10", text: "17.00-18.00" },
        { value: "11", text: "19.00-20.00" },
        { value: "12", text: "20.00-21.00" },
        { value: "13", text: "21.00-22.00" }
      ],
      checkedItems: new Map(),
      loadingAct: false,
      //      errors: {},
      errors: false,
      errorsshow: false,
      notif: null,
      notif2: null,
      list_hari: [],
      data_hari: {},
      list_jams: [],
      data_jams: {},
      list_jamp: [],
      data_jamp: {},
      fields2: {
        nm1: ""
      }
    };
  }
  list_detail = () => {
    fetch("lmn7/spesifikasibook", {
      method: "POST",
      mode: "cors",
      body: JSON.stringify({
        jns_id: "pengajuanbooking2",
        foo2: this.state.foo2
      }),
      headers: new Headers({
        "content-type": "application/json",
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        //  if (data.kode === "00") {
        this.setState({
          list_hari: data.data,
          data_hari: JSON.stringify(data.data)
        });
        //  }
      })
      .catch((error) => {
        this.setState({
          errors: true,
        });
      });
  };
  componentDidMount() {
    this.list_detail();
  }
  loadingAction = () =>
    this.state.loadingAct === true ? (
      <i className="fa fa-spinner fa-spin"></i>
    ) : (
      <div></div>
    );
  notif = () => {
    if (this.state.notif === true) {
      return (
        <div className="alert alert-success alert-dismissible fade show" role="alert" >
          <strong>{this.state.notif2}</strong>
          <button type="button" className="close" data-dismiss="alert" aria-label="Close" >
            <span aria-hidden="true">×</span>
          </button>
        </div>
      );
    } else if (this.state.notif === false) {
      return (
        <div className="alert alert-danger alert-dismissible fade show" role="alert" >
          <strong>Booking Gagal !</strong> {this.state.notif2}
          <button type="button" className="close" data-dismiss="alert" aria-label="Close" >
            <span aria-hidden="true">×</span>
          </button>
        </div>
      );
    } else {
      <></>;
    }
  }
  handleValidation() {
    let fields = this.state.fields;
    let errors = {};
    let formIsValid = true;
    //for huruf match(/^[a-zA-Z]+$/)
    //nama_ruangan
    if (!fields["nama_ruangan"]) {
      formIsValid = false;
      errors["nama_ruangan"] = "nama ruangan tidak boleh kosong";
    }
    //tgl_mulai
    if (!fields["tgl_mulai"]) {
      formIsValid = false;
      errors["tgl_mulai"] = "tanggal mulai tidak boleh kosong";
    }
    //tgl_selesai
    if (!fields["tgl_selesai"]) {
      formIsValid = false;
      errors["tgl_selesai"] = "tanggal selesai tidak boleh kosong";
    }
    this.setState({ errors: errors });
    return formIsValid;
  }
  bookingSubmit = (e) => {
    this.setState({
      btnsimpan: true,
      notif: false,
      msg: ""
    });
    var ncek = $("#ncek").val();
    fetch("/lmn7/bookingdua", {
      method: "POST",
      mode: "cors",
      body: new FormData(e.target),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.kode === "00") {
          var ntahap = $("#tahap").val();
          if (isNaN($("#tahap").val())) {
            var ntahap = 1;
          } else {
            if ($("#tahap").val() === '') {
              var ntahap = 2;
            } else {
              var ntahap = parseInt($("#tahap").val()) + 1;
            }
            $("#tahap").val(ntahap);
          }
          if (ncek === '3' && ntahap < 5) {
            this.setState({
              notif: true,
              pindahhalaman: 11,
              kodepindahhalaman: 'bookingtiga?kode=' + this.state.foo2 + "&tahap=" + ntahap,
              tahap: ntahap,
              msg: data.pesan,
            });
          } else {
            this.setState({
              pindahhalaman: 1,
              notif: true,
              kodepindahhalaman: '/successcechkout?kode=' + data.nkode,
              msg: data.pesan
            });
          }
          //loading
          setTimeout(() => {
            this.setState({
              notif: true,
              btnsimpan: false,
            });
            //reset form
            this.notif();
          }, 2000);
        } else {
          this.setState({
            loadingAct: true,
            notif: true,
            msg: data.pesan,
            btnsimpan: false
          });
          //loading
          /*    setTimeout(() => {
            this.loadingAction();
            this.setState({
              msg: null,
            });
              
          }, 1000); */
        }
      })
      .catch((error) => {
        this.setState({
          errors: true,
          btnsimpan: false
        });
      });
    e.preventDefault();
  };
  handleClick1(e) {
    var tahap = $("#tahap").val();
    var nomor = e.currentTarget.getAttribute('no');
    if (typeof tahap === "undefined") {
      tahap = 1
    }
    $("#ncek").val(nomor);
  }
  handleChange(field, e) {
    let fields = this.state.fields;
    fields[field] = e.target.value;
    this.setState({ fields });
  }
  hendleshow = (e, props) => {
    var tanggal = e.currentTarget.getAttribute('tanggal');
    fetch("/lmn7/showjam", {
      method: "POST",
      mode: "cors",
      body: JSON.stringify({
        jns_id: "pengajuanbooking2",
        foo2: this.state.foo2,
        tanggal: tanggal
      }),
      headers: new Headers({
        "content-type": "application/json",
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        this.setState({
          list_jamp: data.data,
          data_jamp: JSON.stringify(data.data),
          list_jams: data.datas,
          data_jams: JSON.stringify(data.datas)
        });
      })
      .catch((error) => {
        this.setState({
          errorsshow: true,
        });
      });
  }
  hendlejam = (e, props) => {
    var jam = e.currentTarget.getAttribute('tanggal');
    if ($("#" + jam).is(':checked')) {
      var id2 = "add";
    } else {
      var id2 = "del";
    }
    $('#' + jam).prop('checked', true);
    var tanggal = $("input[name='ntanggal']:checked").val();
    fetch("lmn7/savejam", {
      method: "POST",
      mode: "cors",
      body: JSON.stringify({
        jns_id: "pengajuanbooking2",
        foo2: this.state.foo2,
        jam: jam,
        tanggal: tanggal,
        id2: id2
      }),
      headers: new Headers({
        "content-type": "application/json",
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        this.setState({
          list_jamp: data.data,
          data_jamp: JSON.stringify(data.data),
          list_jams: data.datas,
          data_jams: JSON.stringify(data.datas)
        });
        if (data.kode === "88") {
          alert(data.pesan);
        }
      })
      .catch((error) => {
        // this.setState({
        //   errorsshow: true,
        // });
      });
  }
  handleChek(e) {
    var isChecked = e.target.checked;
    var item = e.target.value;
    this.setState(prevState => ({ waktu: prevState.checkedItems.set(item, isChecked) }));
    console.log(this.state.waktu);
  }
  //handleChange11 =(e, props) =>{
  handleChange11({ target }) {
    var params = new window.URLSearchParams(window.location.search);
    let foo2 = params.get('kode');
    var tanggal = $(".nradio:checked").val();
    var jam = target.value;
    if (target.checked) {
      var id2 = "add";
    } else {
      var id2 = "del";
    }
    fetch("lmn7/savejam", {
      method: "POST",
      mode: "cors",
      body: JSON.stringify({
        jns_id: "pengajuanbooking2",
        foo2: foo2,
        jam: jam,
        tanggal: tanggal,
        id2: id2
      }),
      headers: new Headers({
        "content-type": "application/json",
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.kode === "88") {
          alert(data.pesan);
        }
      })
      .catch((error) => {
        // this.setState({
        //   errorsshow: true,
        // });
      });
  }
  render() {
    const { resLogin, pindahhalaman, kodepindahhalaman, segment, tahap } = this.state;
    let htmlbtn = 'Simpan';
    if (tahap > 1) {
      var agenda = " Agenda Ke " + tahap;
    } else {
      var agenda = " Agenda Ke 1";
    }
    if (pindahhalaman == 11) {
      window.history.pushState({}, '', kodepindahhalaman);
      this.setState({
        pindahhalaman: false,
        segment: segment
      });
      $('.hari').prop('checked', false);
      $('.waktu').prop('checked', false);
    }
    if (pindahhalaman == 1) {
      return <Redirect to={kodepindahhalaman} />;
    }
    if (resLogin !== "00") {
      return <Redirect to="/login" />;
      
    }
    return (
      <>
        <Helmet>
          <title>{this.state.fields["nama_ruang"]}</title>
          <meta name="Keywords" content={this.state.fields["nama_ruang"]}></meta>
          <meta name="Description" content={this.state.fields["nama_ruang"]}></meta>
        </Helmet>
        <Main title="booking">
          <section className="page-cover style style5 p-20">
            <div className="container">
              <div className="row">
                <div className="col-md-12 text-center">
                  <h1 className="page-title">Form Booking ruangan (Step {this.state.tahap})</h1>
                </div>
              </div>
            </div>
          </section>
          <section className="innerpage-wrapper pt-5 pb-5">
            <div id="hotel-details" className="innerpage-section-padding">
              <div className="container">
                <h2> </h2>
                <form onSubmit={this.bookingSubmit} id="frmbooking2" name="frmbooking2" >
                  <div className="row">
                    <input type="hidden" className="form-control" name="nidd" value={this.state.foo2} />
                    <input type="hidden" className="form-control" name="tahap" id="tahap" value={this.state.tahap} />
                    <input type="hidden" className="form-control" name="ncek" id="ncek" />
                    <div className="col-md-4 offset-md-12 side-bar ">
                      < div className="row">
                        <label className="col-md-4 offset-md-12 side-bar  "></label>
                        <div className="col-md-7 offset-md-12 side-bar ">
                          <>
                            {this.state.errors === false ? (
                              this.state.list_hari.map(
                                (data_hari, i) => {
                                  return (
                                    <ComGridhari key={data_hari.id} nom={i + 1} changefile={this.hendleshow} data={data_hari} />
                                  );
                                }
                              )
                            ) : (
                              <>-</>
                            )}
                          </>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-7 offset-md-12 side-bar ">
                      <div className="side-bar-block booking-form-block">
                        <div className="row">
                          <div className="col-md-4 offset-md-12 side-bar ">
                            Pagi<br>
                            </br>
                            <div className="form-group">
                              {this.state.errorsshow === false ? (
                                this.state.list_jamp.map(
                                  (data_jamp, i) => {
                                    return (
                                      <ComGridjam key={data_jamp.id} nom={i + 1} changefile={this.handleChange11} data={data_jamp}
                                      // changefile={this.hendlejam}
                                      />
                                    );
                                  }
                                )
                              ) : (
                                <>-</>
                              )}
                            </div>
                          </div>
                          <div className="col-md-4 offset-md-12 side-bar ">
                            Siang
                            <br>
                            </br>
                            <div className="form-group">
                              {this.state.errorsshow === false ? (
                                this.state.list_jams.map(
                                  (data_jams, i) => {
                                    return (
                                      <ComGridjam key={data_jams.id} nom={i + 1} changefile={this.handleChange11} data={data_jams} />
                                    );
                                  }
                                )
                              ) : (
                                <>-</>
                              )}
                            </div>
                          </div>
                        </div>
                        <strong>{this.state.msg} !</strong>
                        <div className="row">
                          <div className="col-md-5 offset-md-12 side-bar ">
                            <button onClick={this.handleClick1} no="1" className="btn btn-block btn-orange mt-4" type="submit" > {this.loadingAction()} Finish </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </section>
        </Main>
      </>
    )
  }
}
export default Bookingdua;

