import React, { Component } from "react";
//import { Link } from "react-router-dom";
import ReactPlayer from 'react-player';
import Slider from "react-slick";
//import Main from "../../layout/Main";
import Compakai from "./Compakai";
import { Card, Table } from "react-bootstrap";
//import axios from "axios";
import moment from 'moment';
class PageDashboardTV extends Component {
  constructor(props) {
    super(props);
    this.state = {
      errors: false,
      total: 0,
      dipakai: 0,
      sisa: 0,
      list_pakai: [],
      data_pakai: {},
    };
  }
  list_p = () => {
    fetch("lmn7/datatvinformasi", {
      method: "POST",
      mode: "cors",
      body: JSON.stringify({
        jns_id: "pengajuan_perubahandata",
        foo: this.state.foo
      }),
      headers: new Headers({
        "content-type": "application/json",
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.kode === "00") {
          this.setState({
            total: data.total,
            sisa: data.sisa,
            dipakai: data.dipakai,
            list_pakai: data.data,
            data_pakai: JSON.stringify(data.data),
          });

        }
      })
      .catch((error) => {
        this.setState({
          errors: true,
        });
      });
  };
  componentDidMount() {
    // this.interval = setInterval(() => {
    this.list_p();
    //  }, 15000);
  }
  render() {
    const settings = {
      autoplay: true,
      speed: 2000,
      autoplaySpeed: 2000,
      dots: false,
      infinite: true,
      slidesToShow: 8,
      slidesToScroll: 1,
      vertical: true,
      verticalSwiping: true,
      beforeChange: function (currentSlide, nextSlide) {
        console.log("before change", currentSlide, nextSlide);
      },
      afterChange: function (currentSlide) {
        console.log("after change", currentSlide);
      }
    };
    const dateTime = new Date();
    return (
      <>
        <div className="container-fluid">
          <div className="p-2">
            <div className="row">

              <div className="col-6">
                <div className="row">
                  <div className="col-8">
                    <Card className="text-center" style={{ background: '#c68c53', color: '#ffffff' }}>
                      <Card.Body>
                        <span className="blink">
                          <h3>{this.state.total} Ruang Rapat</h3>
                          Available Today
                        </span>
                        <hr />
                        <b>{this.state.dipakai}</b> terisi | <b>{this.state.sisa}</b> Belum terpakai
                      </Card.Body>
                    </Card>
                  </div>
                  <div className="col-4">
                    <Card className="text-center" style={{ background: '#f1f2f2', color: '#808285' }}>
                      <Card.Body>
                        <p>{moment(dateTime).format('dddd')}<br /><span style={{ fontSize: "14px" }}>{moment(dateTime).format('MM')}</span><br /><div style={{ marginTop: "10px", marginBottom: "-13px", fontSize: "27px" }}>{moment(dateTime).format('D')}</div><br /><span style={{ fontSize: "14px" }}>{moment(dateTime).format('YYYY')}</span></p>
                      </Card.Body>
                    </Card>
                  </div>
                </div>
                <br />
                <div className="row">
                  <div className="col-12">
                    {/* 
                      <source src="https://www.youtube.com/watch?v=RffICfaRp9U" type="video/mp4" />
                      <source src="https://www.youtube.com/watch?v=RffICfaRp9U" type="video/mp4" />
                      */}
                    <ReactPlayer controls={false}
                      playing={true} loop={true}
                      url='https://www.youtube.com/watch?v=2mYeAojeHLo'
                      width="100%" />
                  </div>
                </div>
              </div>
              <div className="col-6">
                <div className="row">
                  <div className="col-12">
                    <Table striped bordered hover size="sm" className="mt-4">
                      <thead>
                        <tr style={{ background: '#c68c53', color: '#ffffff' }}>
                          <th className="font-weight-bold text-center">RUANGAN TERPAKAI </th>
                        </tr>
                      </thead>
                    </Table>
                    <Table striped bordered hover size="sm" className="mt-4">
                      <thead>
                        <tr>
                          <th className="text-center" width="15%">Tanggal</th>
                          <th className="text-center" width="20%">Ruangan</th>
                          <th className="text-center" width="65%">jam</th>
                        </tr>
                      </thead>
                      {this.state.errors === false ? (
                        this.state.list_pakai.map(
                          (data_pakai, i) => {
                            return (
                              <Compakai
                                key={data_pakai.id}
                                nom={i + 1}
                                data={data_pakai}
                              />
                            );
                          }
                        )
                      ) : (
                        <>-</>
                      )}
                    </Table>
                    <div className="areaTablevertikal">
                      <Slider {...settings}>
                      </Slider>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <section className="page-cover dashboard fixed-bottom position-fixed" style={{ height: "48px" }}>
          <div className="container-fluid">
            <div className="row" >
              <div className="col-12">
                <div className="ml-2 mr-2" style={{ marginTop: "-10px" }}>
                  <marquee>
                    <span className="pr-5">Selamat Datang di Nuansa Unsoed, silahkan untuk menikmati fasilitas terbaik kami</span>
                    <span className="pr-5">Selamat Datang di Nuansa Unsoed, silahkan untuk menikmati fasilitas terbaik kami</span>
                    <span className="pr-5">Selamat Datang di Nuansa Unsoed, silahkan untuk menikmati fasilitas terbaik kami</span>
                    <span className="pr-5">Selamat Datang di Nuansa Unsoed, silahkan untuk menikmati fasilitas terbaik kami</span>
                    <span className="pr-5">Selamat Datang di Nuansa Unsoed, silahkan untuk menikmati fasilitas terbaik kami</span>
                    <span className="pr-5">Selamat Datang di Nuansa Unsoed, silahkan untuk menikmati fasilitas terbaik kami</span>
                    <span className="pr-5">Selamat Datang di Nuansa Unsoed, silahkan untuk menikmati fasilitas terbaik kami</span>
                    <span className="pr-5">Selamat Datang di Nuansa Unsoed, silahkan untuk menikmati fasilitas terbaik kami</span>
                    <span className="pr-5">Selamat Datang di Nuansa Unsoed, silahkan untuk menikmati fasilitas terbaik kami</span>
                  </marquee>
                </div>
              </div>
            </div>
          </div>
        </section>
      </>
    );
  }
}

export default PageDashboardTV;
