import React, { Component } from "react";
import { Link, Redirect } from "react-router-dom";
import Main from "../../layout/Main";
//import { Container, Row, Col, Card, Form, Button } from "react-bootstrap";
//import axios from "axios";

class PageSignup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      resLogin: localStorage.getItem("login_public") ? JSON.parse(localStorage.getItem("login_public")).kode : null,
      msg: ""
    };
  }
  daftarSubmit = (e) => {
    this.setState({
      btnsimpan: true,
      notif: false,
      msg: ""
    });
    fetch("lmn7/daftarakun", {
      method: "POST",
      mode: "cors",
      body: new FormData(e.target),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.kode === "00") {
          this.setState({
            notif: true,
            kodepindahhalaman: '/successcechkout?kode=' + data.nkode,
            msg: data.pesan,
          });
          //loading
          setTimeout(() => {
            this.setState({
              pindahhalaman: true,
              notif: true,
              btnsimpan: false,
            });
            //reset form
            this.notif();
          }, 2000);
        } else {
          this.setState({
            loadingAct: true,
            notif: true,
            msg: data.pesan,
            btnsimpan: false
          });
          //loading
          /*    setTimeout(() => {
            this.loadingAction();
            this.setState({
              msg: null,
            }); 
          }, 1000); */
        }
      })
      .catch((error) => {
        this.setState({
          errors: true,
          btnsimpan: false
        });
      });
    e.preventDefault();
  };
  render() {
    const { pindahhalaman, kodepindahhalaman } = this.state;
    let htmlbtn = 'Simpan';
    if (pindahhalaman) {
      return <Redirect to="login" />;
    }
    return (
      <Main>
        <div>
          <section className="innerpage-wrapper" id="full-page-form">
            <div id="registration" className="innerpage-section-padding">
              <div className="container">
                <div className="row">
                  <div className="col-md-12">
                    <div className="flex-content">
                      <div className="custom-form custom-form-fields">
                        <h3>Registration</h3>
                        <p class="msg">
                          {this.state.msg}
                        </p>
                        <form onSubmit={this.daftarSubmit} id="hotel_frm_daftar" name="hotel_frm_daftar" >
                          <div className="form-group">
                            <input type="text" className="form-control" placeholder="Nama" name="userlogin" maxlength="18" required /> <span><i className="fa fa-user" /></span>
                          </div>
                          <div className="form-group">
                            <input type="text" className="form-control" placeholder="No HP" name="nohp" maxlength="18" required /> <span> <i className="fa fa-user" /></span>
                          </div>
                          <div className="form-group">
                            <input type="email" className="form-control" placeholder="Email" maxlength="50" name="email" required /> <span> <i className="fa fa-envelope" /></span>
                          </div>
                          <div className="form-group">
                            <input type="password" className="form-control" placeholder="Password" maxlength="18" name="pwd" required /> <span><i className="fa fa-lock" /></span>
                          </div>
                          <div className="form-group">
                            <input type="password" className="form-control" placeholder="Confirm Password" maxlength="18" name="password_confirm" required /> <span><i className="fa fa-lock" /></span>
                          </div>
                          <button className="btn btn-secondary btn-block"> Register </button>
                        </form>
                        <div className="other-links"> <p className="link-line">  Already Have An Account ? <Link to="/login">Login Here</Link> </p> </div>
                      </div>
                      <div className="flex-content-img custom-form-img">
                        <img src="assets/images/background/log.jpg" className="img-fluid" alt="registration-img" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </Main>
    );
  }
}

export default PageSignup;
