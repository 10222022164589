import React from "react";
//import { Col, Button, Card } from "react-bootstrap";
import { Link } from "react-router-dom";

const Comtemp = (props) => {
  return (
    <>
      <tr>
        <td>{props.nom}</td>
        <td>{props.data.ket}</td>
      </tr>
    </>
  );
};

export default Comtemp;
