import React from "react";
//import { Col, Button, Card } from "react-bootstrap";
import { Link } from "react-router-dom";

const ComListRuangan = (props) => {
  //let nama="detailruangan?kode="+props.data.kode;
  return (
    <>
      <div className="list-block main-block h-list-block" style={{ width: '100%' }}>
        <div className="list-content">
          <div className="main-img list-img h-list-img">
            <Link to={'detailruangan?kode=' + props.data.url}>
              <img src={props.data.url_image} className="img-fluid" alt={props.data.nama_ruang} />
            </Link>
            <div className="main-mask">
              <ul className="list-unstyled list-inline offer-price-1">
                <li className="list-inline-item price">
                  {props.data.spek_ruang}<span className="divider">|</span>
                  <span className="pkg">Lantai {props.data.kd_lantai}</span>
                </li>
              </ul>
            </div>
          </div>
          <div className="list-info h-list-info">
            <h3 className="block-title">
              <Link to={'detailruangan?kode=' + props.data.url}>{props.data.nama_ruang}</Link>
            </h3>
            <p className="block-minor mt-2">Ukuran: -{props.data.luas_ruang}--</p>
            <p>
              {props.data.title}
            </p>
            <Link to={'detailruangan?kode=' + props.data.url} className="btn btn-secondary btn-lg">View More</Link>
          </div>
        </div>
      </div>
    </>
  );
};

export default ComListRuangan;
